import React, { useState, useRef } from 'react';
import { useReactToPrint } from "react-to-print";
import { WhatsappShareButton } from "react-share";
import { FiEdit } from 'react-icons/fi';
import FormContact from '../FormContact';
import FormSurvey from '../FormSurvey';
import PropertyFile from './PropertyFile';
import Favorites from '../../../../helpers/Favorites';

import whatsapp from '../../../../assets/images/icons/whatsapp.svg';
import print from '../../../../assets/images/icons/print.svg';

import './styles.css';

export default function Contact(props) {
    const [modalContact, setModalContact] = useState(false);
    const [modalSurvey, setModalSurvey] = useState(false);

    const _favorites = new Favorites();

    const toggleContact = () => {setModalContact(!modalContact)}
    const toggleSurvey = () => {setModalSurvey(!modalSurvey)}

    const componentRef = useRef(); 
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <div id="property-contact">
            <i 
                id={`icon_${props.imovel.id}`}
                onClick={() => _favorites.update(props.imovel)}
                className={_favorites.getIcons(props.imovel.id, 'heart')}
            />

            <h2>
                {props.imovel.modalidade === 'aluguel' ? 'Aluguel':'Venda'} &nbsp;
                <span>R$ {props.imovel.valor}</span>
            </h2>

            <ul>
                <li>
                    <span>Condomínio</span>
                    <span>{props.imovel.valor_condominio !== '0,00' && props.imovel.valor_condominio !==  0 ? 
                        `R$ ${props.imovel.valor_condominio}/mês`:`Não informado`}
                    </span>
                </li>
                <li>
                    <span>IPTU</span>
                    <span>{props.imovel.valor_iptu !== '0,00' && props.imovel.valor_iptu !==  0 ? 
                        `R$ ${props.imovel.valor_iptu}/mês`:`Não informado`}
                    </span>
                </li>
            </ul>

            <div className="block-alert">
                <div className="alert">
                    <FiEdit/>
                    <p>
                        Para agilar o seu processo preencha a ficha de Cadastral 
                        Digital e envie os documentos necessários para avaliação
                    </p>
                </div>

                <button>Cadastro digital - clique para iniciar</button>
            </div>

            <button type='button' onClick={()=>toggleSurvey()}>
                Agendar visita
            </button>

            <button type='button' onClick={()=>toggleContact()}>
                Mais informações
            </button>            

            <FormContact isOpen={modalContact} toggle={toggleContact} imovel={props.imovel} />
            <FormSurvey isOpen={modalSurvey} toggle={toggleSurvey} imovel={props.imovel}/>

            <div className="box-share">
                <div className="content-box">

                <img src={print} alt="Imprimir detalhes" onClick={handlePrint}/>
                <div style={{ display: "none" }}><PropertyFile ref={componentRef} {...props} /></div>                    
                    Imprimir ficha do imóvel
                </div>
                
                <div className="content-box">
                    <WhatsappShareButton 
                        children={<img src={whatsapp} alt="compartilhar Whatsapp"/> }
                        windowWidth={700}
                        windowHeight={700}
                        url={window.location.href} />
                    Compartilhar com um amigo
                </div>
            </div>
            
        </div>
    )
}