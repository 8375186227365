import React from 'react';
import { Link } from 'react-router-dom';

import './styles.css';

import caixaAqui from '../../assets/images/caixa-aqui-logo.png';

export default function CaixaAqui() {
    return (
        <div className="block-CEF">
            <Link to={{ pathname: process.env.REACT_APP_CAIXA }} target='_blank'>
                <h2><span>Compre seu imóvel aqui</span> somos correspondente Caixa</h2>                
                <img src={caixaAqui} alt="CEF"/>
            </Link> 
        </div>
    )
}