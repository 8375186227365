import React from 'react';
import LoadingMask from "react-loadingmask";
import Gallery from './Gallery';
import Details from './Details';
import Location from './Location';
import Contact from './Contact';
import CaixaAqui from '../../../components/CaixaAqui';

import './styles.css';

const Content = (props) => {
    return (
        <div id='property-details'>
                <div className="content">     
                    <LoadingMask loading={props.loadingContent} >
                        <Gallery data={props.imovel.imagens} />
                        <Details {...props} />
                        <Location {...props}/>
                    </LoadingMask>
                </div>
                <div className="contact">
                    <LoadingMask loading={props.loadingContent} >
                        <Contact {...props}/>
                    </LoadingMask>

                    {props.imovel.modalidade === 'venda' && <CaixaAqui/> }                    
                </div>
        </div>
    );
}

export default Content