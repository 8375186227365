import React from  'react';
import { Link } from 'react-router-dom';
import { FiPhone, FiMail, FiClock, FiMapPin, FiFacebook, FiInstagram, FiTwitter, FiYoutube } from 'react-icons/fi';
import { FaWhatsapp, FaCopyright } from 'react-icons/fa';

import './styles.css';

export default function Footer() {
    const now = new Date();   
    return (
        <footer>
            <div className="container">
                <div className="footer-box">
                    <h2><Link to='/'>{process.env.REACT_APP_NAME}</Link></h2>
                    <ul>
                        <li><Link to='/sobre-nos'>Sobre nós</Link></li>
                        <li><Link to='/' target='_blank'>Blog</Link></li>
                        <li><Link to='/aluguel/apartamento/fortaleza'>Imóveis para alugar</Link></li>
                        <li><Link to='/venda/apartamento/fortaleza'>Imóveis à venda</Link></li>
                    </ul>
                </div>
                <div className="footer-box">
                    <h2>Serviços</h2>
                    <ul>
                        <li><Link to={{pathname: process.env.REACT_APP_LOGIN}} target='_blank'>Segunda via de boleto</Link></li>
                        <li><Link to={{pathname: process.env.REACT_APP_LOGIN}} target='_blank'>Prestação de contas</Link></li>
                        <li><Link to={{pathname: process.env.REACT_APP_LOGIN}} target='_blank'>Extrato de IRRF</Link></li>
                        <li><Link to='/cadastre-seu-imovel'>Anunciar meu imóvel</Link></li>
                        <li><Link to='/agendamento-de-vistoria'>Agendar vistoria</Link></li>
                        <li><Link to={{ pathname: process.env.REACT_APP_CAIXA }} target='_blank' >Simulador financeiro</Link></li>                       
                    </ul>
                </div>
                <div className="footer-box contact">
                    <h2>Nossos contatos</h2>
                    <ul>
                        <li>
                            <Link to={{pathname: process.env.REACT_APP_WHATSAPP_API}} target="_blank">
                                <FaWhatsapp />
                                <strong>{process.env.REACT_APP_DDD} {process.env.REACT_APP_WHATSAPP}</strong>
                            </Link>
                        </li>
                        <li>
                            <FiPhone/>
                            {process.env.REACT_APP_DDD} {process.env.REACT_APP_PHONE}
                            {process.env.REACT_APP_PHONE_RENT}
                        </li>
                        <li>
                            <FiMail/>
                            {process.env.REACT_APP_EMAIL_RENT}                           
                        </li>
                        <li>
                            <FiMail/>
                            {process.env.REACT_APP_EMAIL_SALE}                           
                        </li>
                    </ul>                    
                </div>
                <div className="footer-box">
                    <h2>
                        <FiClock/>
                        Horário de funcionamento
                    </h2>
                    <ul>
                        <li>Segunda à sexta-feira de 08h às 17h</li>
                    </ul>

                    <br/>

                    <h2>
                        <FiMapPin/>
                        Nosso endereço
                    </h2>
                    <ul>
                        <li>{process.env.REACT_APP_ADDRESS}</li>
                        <li>CRECI {process.env.REACT_APP_CRECI}</li>
                    </ul>
                </div>
            </div>
            <div className="box-bottom">                   
                <div className="medias">
                    <Link to={{pathname: process.env.REACT_APP_FACEBOOK}} target="_blank"><FiFacebook/></Link>
                    <Link to={{pathname: process.env.REACT_APP_INSTAGRAM}} target="_blank"><FiInstagram/></Link>
                    <Link to={{pathname: process.env.REACT_APP_YOUTUBE}} target="_blank"><FiYoutube/></Link>
                    <Link to={{pathname: process.env.REACT_APP_TWITTER}} target="_blank"><FiTwitter/></Link>
                </div>
                <div className="copy-right">
                    <FaCopyright/> {now.getFullYear()} - Todos os direitos reservados - 
                    <Link to='/'>{process.env.REACT_APP_NAME}</Link> -
                    jordaoimobiliaria.com.br
                </div>
            </div>
        </footer>
    )
}