import React from 'react';

import './styles.css';

class PropertyFile extends React.Component {
    render() {
        return (
            <div id="property-file">
                <h2>Ficha do imóvel {this.props.imovel.id}</h2>
                <address>{this.props.imovel.endereco} - {this.props.imovel.bairro}</address>

                <div className="block-image"> 
                    {this.props.imovel.imagens.slice(0, 3).map((item, index) => (                      
                        <img key={index} src={item.imagem} alt={item.descricao}/>                 
                    ))}
                </div>

                <br/>
                
                <hr/>

                <br/>

                <h2>{this.props.imovel.tituloDetalhes}</h2>
                <p>
                    {this.props.imovel.descricao}
                </p>

                <div className="features">
                    <span>
                        {this.props.imovel.area_total}m² de área, &nbsp;
                        {this.props.imovel.quartos} quartos, &nbsp;
                        {this.props.imovel.suites} suítes, &nbsp;
                        {this.props.imovel.banheiros} banheiros e &nbsp;
                        {this.props.imovel.vagas} vagas</span>
                </div>

                <br/>

                <div className="block-list-features">
                    <h2>Detalhes do imóvel</h2>
                    <ul>
                        {this.props.imovel.caracteristicas.map((item, index) => ( 
                            <li key={index}>
                                <i className="fas fa-check"/>&nbsp;&nbsp;
                                {item.nome}
                            </li>
                        ))}                            
                    </ul>
                </div>               

                <div className="block-list-features">
                    <h2>Detalhes do edifício</h2>
                    <ul>
                        {this.props.imovel.condominio.map((item, index) => ( 
                            <li key={index}>
                                <i className="fas fa-check"/>&nbsp;&nbsp;
                                {item.nome}
                            </li>
                        ))}
                    </ul>
                </div>

                <br/>

                <div className="block-details">
                    <h3>{this.props.imovel.modalidade}: <span>R$ {this.props.imovel.valor}</span></h3>
                    <h3>condomínio: <span>R$ {this.props.imovel.valor_condominio}</span></h3>
                    <h3>IPTU: <span> {this.props.imovel.valor_iptu}</span></h3>
                </div>

                <br/>
                <br/>

                <div className="block-contact">
                    <strong> {process.env.REACT_APP_NAME}</strong> <br/>
                    E-mail: <strong>{process.env.REACT_APP_EMAIL_RENT}</strong> <br/>
                    Telefone: <strong>{process.env.REACT_APP_DDD} {process.env.REACT_APP_WHATSAPP}</strong> | <strong>{process.env.REACT_APP_DDD} {process.env.REACT_APP_PHONE}</strong> <br/>
                    Para mais informações visite nosso site <strong>www.jordaoimobiliaria.com.br</strong> e procure o imóvel pelo código <strong>{this.props.imovel.id}</strong>
                </div>

            </div>
        );
    }
}

export default PropertyFile;