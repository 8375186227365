import React, { useState }  from 'react';
import { Form, Input, Button } from "reactstrap";
import MaskedInput from 'react-text-mask';
import Swal from 'sweetalert2';
import LoadingMask from "react-loadingmask";

import api from '../../../services/api';

import './styles.css';

const tiposVistoria = [
    'Vistoria inicial',
    'Vistoria eventual',
    'Vistoria de devolução',    
    'Avaliação',
    'Captação',
];

export default function FormRegistration (){

    const [state, setState] = useState({
        tipo: 'Vistoria inicial',
        modalidade: 'Aluguel',
        contrato: '',
        codigo: '',
        endereco: '',
        nome: '',
        email: '',
        telefone: '',
        celular: '',
        observacao:''
    });

    const [loading, setLoading] = useState(false);

    const handleOnChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        });
    }

    const clear = () => {
        setState({
            tipo: 'Vistoria inicial',
            modalidade: 'Aluguel',
            contrato: '',
            codigo: '',
            endereco: '',
            nome: '',
            email: '',
            telefone: '',
            celular: '',
            observacao:''
        })
    }

    const handleSubmitAgendamentoVistoria = (event) => {
        event.preventDefault();
        setLoading(true);
        
        const {tipo, modalidade, contrato, codigo, endereco, nome, email, telefone, celular, observacao} = state;

        const dados = {   
            tipo,
            modalidade,
            contrato,
            codigo,
            endereco,
            nome,
            email,
            telefone,
            celular,
            observacao
        }

        api.post('/email/agendamentoDeVisitoria', dados).then( response => {
            setLoading(false);
    
            if (response.data.code === 1) {
                getAlert('success', 'Dados enviado com sucesso!');          
                clear();
            }
    
            if (response.data.code === 2) {
                getAlert('error', 'Ooops! Erro no envio dos dados.');
            }    
        });

        const getAlert = (icon, message) => {
            return Swal.fire({
                position: 'center',
                icon: icon,
                title: message,
                showConfirmButton: false,
                timer: 1500
            });
        }
    }

    return (
        <div id="survey-block-form" >
            <Form onSubmit={event => handleSubmitAgendamentoVistoria(event)}>
            <LoadingMask loading={loading} >               
                <div className="block-group">                    
                    <Input
                        type="select"
                        name="tipo"
                        onChange={(event)=>handleOnChange(event)}
                        value={state.tipo} >
                        
                        {tiposVistoria.map((tipo, index) => (
                            <option key={index} value={tipo}>{tipo}</option>
                        ))}
                    </Input>
                    <Input                         
                        type="select"
                        name="modalidade"
                        onChange={(event)=>handleOnChange(event)}
                        value={state.modalidade} >

                        <option value='Aluguel'>Aluguel</option>
                        <option value='Venda'>Venda</option>
                    </Input>                   
                </div>

                <div className="block-group">
                    <Input
                        name="contrato"
                        type="text"
                        placeholder="Número do contrato"
                        onChange={(event)=>handleOnChange(event)}
                        value={state.contrato.toUpperCase()} />

                    <Input                         
                        name="codigo"
                        type="text"
                        placeholder="Código do imóvel"
                        onChange={(event)=>handleOnChange(event)}
                        value={state.codigo.toUpperCase()} />
                </div>

                <Input                         
                    name="endereco"
                    type="text"
                    placeholder="Endereço do imóvel"
                    required={true}
                    onChange={(event)=>handleOnChange(event)}
                    value={state.endereco} />

                <Input 
                    name="nome"
                    type="text"
                    placeholder="Seu nome"
                    required={true}
                    onChange={(event)=>handleOnChange(event)}
                    value={state.nome} />

                <Input
                    name="email"
                    type="email"
                    placeholder="Seu e-mail"
                    required={true}
                    onChange={(event)=>handleOnChange(event)}
                    value={state.email} />

                <div className="block-group">
                    <MaskedInput
                        className="form-control"
                        name='telefone'
                        mask={['(', /[1-9]/, /\d/, ')', ' ',/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                        placeholder="Seu telefone"                            
                        guide={false}
                        onChange={(event)=>handleOnChange(event)}
                        value={state.telefone} />

                    <MaskedInput
                        className="form-control"
                        name='celular'
                        mask={['(', /[1-9]/, /\d/, ')', ' ',/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                        placeholder="Seu celular"                            
                        guide={false}
                        required={true}
                        onChange={(event)=>handleOnChange(event)}
                        value={state.celular} />
                </div>

                <Input
                        name="observacao"
                        type="textarea"
                        placeholder="Alguma observação?"
                        onChange={(event)=>handleOnChange(event)}
                        value={state.observacao} />

                <Button type="submit">Agendar Vistoria</Button>                                       
            </LoadingMask>
            </Form>
        </div>
    );
}