export const tipoImovelDistintos = {
    residencial: [
        {name: 'apartamento'},
        {name: 'casa'},
        {name: 'flat'},
        {name: 'sítio'},
        {name: 'terreno'}      
    ],
    comercial: [   
        {name: 'casa comercial'},
        {name: 'galpão'},
        {name: 'ponto comercial'},
        {name: 'sala'},
        {name: 'terreno'}      
    ]    
}

export const tipoImovelOptions = [
    {name: 'Apartamento', value: 'apartamento'},
    {name: 'Casa', value: 'casa'},
    {name: 'Loja', value: 'loja'},
    {name: 'Ponto comercial', value: 'ponto-comercial'},
    {name: 'Galpão', value: 'galpao'},
    {name: 'Sala', value: 'sala'},
    {name: 'Terreno', value: 'terreno'}
]

export const postsBlog = [
    {
        image: '/temp/blog.jpg',
        title: 'Estamos de site novo',
        text: 'Lorem ipsum dolor sit amet consectetur adipisicing',
        link: '/'
    },
    {
        image: '/temp/blog.jpg',
        title: 'Estamos de site novo',
        text: 'Lorem ipsum dolor sit amet consectetur adipisicing',
        link: '/'
    },
    {
        image: '/temp/blog.jpg',
        title: 'Estamos de site novo',
        text: 'Lorem ipsum dolor sit amet consectetur adipisicing',
        link: '/'
    }
]

export const listPopularProperties = [
    {
        label: 'Casa à venda no Meireles',
        to: '/venda/casa/meireles'
    },
    {
        label:  'Salas para alugar',
        to: '/aluguel/sala/fortaleza'
    },
    {
        label : 'Casa para vender',
        to : '/venda/casa/fortaleza'
    },
    {
        label : 'Casa à venda na Aldeota',
        to : '/venda/casa/aldeota'
    },    
    {
        label : 'Salas à venda',
        to : '/venda/sala/fortaleza'
    },
    {
        label : 'Salas para alugar',
        to : '/aluguel/sala/fortaleza'
    },
    {
        label : 'Casa para alugar',
        to : '/aluguel/casa/fortaleza'
    },
    {
        label : 'Salas para alugar no Benfica',
        to : '/aluguel/sala/benfica'
    },
    {
        label : 'Apartamento para alugar na Aldeota',
        to : '/aluguel/apartamento/aldeota'
    },
    {
        label : 'Salas para alugar no Montese',
        to : '/aluguel/sala/montese'
    },
    {
        label : 'Loja para alugar Centro',
        to : '/aluguel/loja/centro'
    },
    {
        label : 'Salas para alugar no Benfica',
        to : '/aluguel/sala/benfica'
    },
    {
        label : 'Salas à venda',
        to : '/venda/sala/fortaleza'
    }
];

export const gallery = [
    '/images/demo/gallery/gallery01.jpg',
    '/images/demo/gallery/gallery02.jpg',
    '/images/demo/gallery/gallery03.jpg',
    '/images/demo/gallery/gallery04.jpg',
    '/images/demo/gallery/gallery05.jpg',
    '/images/demo/gallery/gallery06.jpg'
]

export const caracteristicas = [
    {nome: 'Área de serviço'},
    {nome: 'Cozinha'},
    {nome: 'Quartos: 02'},
    {nome: 'Suítes:  03'},
    {nome: 'Vagas: 02'},
    {nome: 'Banheiros: 02'},
    {nome: 'Varanda'},
    {nome: 'Sala de estar'},
    {nome: 'Dependência'}
]

export const condominio = [
    {nome: 'Salão de festas'},
    {nome: 'Quadra de esporte'},
    {nome: 'Cerca elétrica'},
    {nome: 'Guarita de segurança'},
    {nome: 'Câmeras'},
    {nome: 'Portaria 24h'},
]