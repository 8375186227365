import React from 'react';
import Headline from '../../../components/Headline';

import './styles.css';

export default function Content() {
    return (
        <div id="about-us">
            <Headline label='Veja como tudo começou' />
            <div className="container">                
                <h2>APRESENTAÇÃO DA JORDÃO IMOBILIÁRIA</h2>

                <p>Graças ao trabalho árduo e inovador de José Lopes Filho, através da 
                ESAPRE, a Jordão Imobiliária dá continuidade a uma tradição de mais de 
                50 anos na administração imobiliária. Visionário, iniciou esse nicho de 
                mercado em Fortaleza na década de sessenta, sempre norteado pela 
                dedicação e presteza no atendimento de seus clientes.</p>

                <p>Atualmente, mantém o compromisso para com seus clientes, inovando em 
                novas práticas, proporcionando-lhes uma experiência mais alinhada as 
                novas tecnologias e a eficiência na prestação de seus serviços.</p>

                <p>Conta com uma equipe treinada e com vasta vivencia no tratamento e 
                resolução de impasses na administração predial, como também na 
                corretagem para compra e venda de imóveis e assessoria jurídica para 
                solução de judicial e extra de conflitos, além de firmar parceira com 
                corretoras de seguros e a Caixa Econômica Federal, fornecendo aos 
                clientes mais facilidade na contratação de seguros residenciais e 
                financiamento bancário.</p>

                <p>Com isso, a Jordão Imobiliária pretende comungar a tradição a inovação 
                como vetores na busca da excelência na gestão de imóveis em Fortaleza.</p> 
            </div>
        </div>
    )
}
