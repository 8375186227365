import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-owl-carousel2/lib/styles.css';
import 'leaflet/dist/leaflet.css';
import 'react-select2-wrapper/css/select2.css';
import "react-loadingmask/dist/react-loadingmask.css";
import './assets/css/global.css';

import Routes from './routes';

function App() {
  return (
    <Routes />
  );
}

export default App;
