import React,{ useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel2';
import Headeline from '../../../components/Headline';
import Utils from '../../../helpers/Utils';

import api from '../../../services/api';

import './styles.css';

export default function FeaturedProperties() {
    const [rent, setRent] = useState([]);
    const [sale, setSale] = useState([]);

    const options = {
        margin: 10,
        responsive: {0: {items: 1}, 768: {items: 2}, 990: {items: 3}}
    };

    const carousel = useRef(null);

    const listDestaques =  async() => {        
        await api.get(`/aluguel/destaques`).then(response => {setRent(response.data.imoveis)});
        await api.get(`/venda/destaques`).then(response => {setSale(response.data.imoveis)});
    }

    useEffect(() => {
        listDestaques();
    }, []);

    const getSlug = (tipo, quartos, suites, bairro, cidade, estado) => {
        let dormitorios_total = quartos + suites;
        let slug = '';

        if (dormitorios_total === 1) {
            slug = `${tipo}-${dormitorios_total}-dormitorio-no-bairro-${bairro}-em-${cidade}-${estado}`;
        } else if (dormitorios_total > 1) {
            slug = `${tipo}-${dormitorios_total}-dormitorios-no-bairro-${bairro}-em-${cidade}-${estado}`;
        } else {
            slug = `${tipo}-no-bairro-${bairro}-${cidade}-${estado}`;
        }

        return Utils.transliterate(slug);
    }

    return (
        <section id="featured-properties">
            <Headeline label='Imóveis em destaque'/>
            <div className="container">

                {rent.length > 0 || sale.length > 0 ?
                    <OwlCarousel ref={carousel} options={options}>
                        {rent.concat(sale).map((imovel, index) => (                  
                            <div key={index} className="item">
                                <div className="especial-card">
                                    <div className="image">
                                        <div className="content-link"> <span>Mais detahes</span></div>
                                        <Link to={`/detalhes/${imovel.modalidade}/${imovel.id}/${getSlug(imovel.tipo, imovel.quartos, imovel.suites, imovel.bairro, imovel.cidade, imovel.estado)}`}>
                                            <img src={imovel.foto} alt={imovel.titulo}/>
                                        </Link>
                                        
                                    </div>                            
                                    <div className="wrapper">
                                        <h2>
                                            <Link to={`/detalhes/${imovel.modalidade}/${imovel.id}/${getSlug(imovel.tipo, imovel.quartos, imovel.suites, imovel.bairro, imovel.cidade, imovel.estado)}`}>
                                                {imovel.titulo}
                                            </Link>
                                        </h2>
                                        <address>{imovel.bairro}, {imovel.cidade} - {imovel.estado}</address>
                                        <div className="values">
                                            <span>R$ {imovel.valor.replace(',00','')}</span>
                                            <span>{imovel.area_total.replace(',00','')}m²</span>
                                        </div>
                                        <ul>
                                            <li>{imovel.quartos} quarto{imovel.quartos > 1 ? 's':''}</li>
                                            <li>{imovel.suites} suíte{imovel.suites > 1 ? 's':''}</li>
                                            <li>{imovel.banheiros} wc{imovel.banheiros > 1 ? "'s":""}</li>
                                            <li>{imovel.vagas} vaga{imovel.vagas > 1 ? 's':''}</li>
                                        </ul>
                                    </div>
                                </div>                        
                            </div>
                        ))}
                    </OwlCarousel>
                :''}

                <div className="carousel-nav">
                    <span onClick={() => carousel.current.prev()}><i className="fas fa-angle-left"/></span>
                    <span onClick={() => carousel.current.next()}><i className="fas fa-angle-right"/></span>
                </div>
            </div>            
        </section>
    );
}