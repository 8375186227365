import React from 'react';
import { Link } from 'react-router-dom';
import  { FiChevronsRight } from 'react-icons/fi';
import Headeline from '../../../components/Headline';
import * as constants from '../../../data';

import './styles.css';

export default function PopularProperties() {
    return (
        <section id="popular-properties">
            <Headeline label='Busca rápida'/>
            <div className="container">
                <ul>
                    {constants.listPopularProperties.map((item, i) =>(
                        <li key={i}>
                            <Link to={item.to}>
                            <FiChevronsRight />
                            {item.label}
                            </Link>
                        </li>
                    ))}                    
                </ul>
            </div>            
        </section>
    );
}