import React from 'react';
import { Form, Input } from "reactstrap";
import TitleLoader from '../../../../components/TitleLoader';
import Drawer from '../Drawer';
import Utils from '../../../../helpers/Utils';
import CaixaAqui from '../../../../components/CaixaAqui';

import './styles.css';

export default function Topo(props) {
    const openDrawer = () => {
        document.querySelector('#drawer-search').classList.remove('no-drawer-search');
        document.querySelector('#drawer-search-overlay').classList.remove('no-overlay');
    }

    let title = '';
    if (props.total > 0) {
        title =
            `${props.total} imóve${props.total > 1 ? 'is':'l'} do tipo
             ${props.tipo} ${props.modalidade === 'aluguel' ? 'para alugar':'à venda'} em 
             ${Utils.capitalize(props.endereco)}`
    } else {
        title = 'Não foram encontrados imóveis para a busca realizada';
    }

    return(
        <>
            {props.modalidade === 'venda' && <CaixaAqui/>}            

            <div className="topo-content">  
                { props.loadingContent ? <TitleLoader /> : <h1>{ title }</h1> }

                <div className="block-buttons">
                    <button type='button' onClick={openDrawer}>Filtros</button>
                    <Drawer {...props} />

                    <Form>
                        <Input                         
                            type="select"
                            name="order"
                            onChange={(event)=>props.handleSelect(event)}
                            value={props.order} >

                            <option value=''>Ordenar por</option>
                            <option value='menor-preco'>Menor preço</option>
                            <option value='maior-preco'>Maior preço</option>
                            <option value='menor-area'>Menor área</option>
                            <option value='maior-area'>Maior área</option>
                        </Input>
                    </Form>
                </div>
            </div>            
        </>
    );
}