import React, { Fragment } from 'react';
import { FiX } from 'react-icons/fi';
import FormSearch from '../FormSearch';

import './styles.css';

export default function Drawer(props) {
    const closeDrawer = () => {
        document.querySelector('#drawer-search-overlay').classList.add('no-overlay');
        document.querySelector('#drawer-search').classList.add('no-drawer-search');
    }

    return(
        <Fragment>
            <div id="drawer-search-overlay" className="overlay no-overlay" onClick={closeDrawer}></div>
            <div id="drawer-search" className="drawer-search no-drawer-search">
                <FiX 
                    size={20} 
                    color={'var(--color-primary-light)'}
                    onClick={closeDrawer} 
                    style={{cursor:'pointer', margin: '0 10px 10px'}}
                />
                
                <FormSearch {...props} close={closeDrawer}/>                
                
            </div>
        </Fragment>
    );
}