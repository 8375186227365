import React from 'react'
import { FiShare } from 'react-icons/fi';
import { Link as Botao} from 'react-scroll';
import Headline from '../../../components/Headline';

import './styles.css';

export default function Instruction() {
    return (
        <section id="instruction">
            <div className="container">
                <Headline label="Quero divulgar meu imóvel, como faço?" />

                <p>
                    Preencha o formulário do início desta página. E pronto.
                    Entraremos em contato para agendar uma visita e seguir com os demais detalhes da divulgação.
                </p>

                <Botao 
                    to="topo-property-registration" 
                    smooth={true} 
                    duration={800}
                    className="btn btn-primary"                            
                >
                    Anunciar meu imóvel &nbsp;
                    <FiShare/>
                </Botao>
            </div>
        </section>
    );
}